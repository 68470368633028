import modal from 'bootstrap/js/dist/modal'
import Swiper from 'swiper'

(function($) {
    $(document).ready(function() {
        /**
         * Sidemenu
         */
        $('.sidemenu__toggle').click(() => {
            $('body').toggleClass('sidemenu--open')
        })
    
        $('.sidemenu .menu-item-has-children > a').each((index, element) => {
            $(element).append('<span></span>');
        })
    
        $('.sidemenu .menu-item-has-children span').click((event) => {
            event.preventDefault()
            $(event.target).parent('a').parent('.menu-item-has-children').toggleClass('sidemenu-item--open')
        })
    
        /**
         * Header Search Form
         */
        $('.header__search-toggle').click(() => {
            $('body').toggleClass('header__search--open')
        })
    
        /**
         * Footer Mobile Menu
         */
        $('.footer__menu .menu-item-has-children > a').click((event) => {
            event.preventDefault()
            $(event.target).parent('.menu-item-has-children').toggleClass('footer-menu-item--open')
        })
    
        /**
         * Contact form load more
         */
        $('#contact-form-load-more').click((event) => {
            event.preventDefault()
            $('.contact-form').removeClass('contact-form--hidden')
        })
    
        
        /**
         * Home hero slider
         */
        const homeHeroSwiper = new Swiper('.home-hero__slider', {
            loop: true,
            autoplay: true,
            speed: 1100,
            slidesPerView: 1,
            autoplay: {
                delay: 6000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        })
    
        /** 
         * Latest news slider homepage
        */
        const latestArticlesSwiper = new Swiper('.latest-articles__slider', {
            loop: false,
            autoplay: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    
        /** 
         * Solutions slider
        */
        const solutionsSwiper = new Swiper('.solutions__slider', {
            loop: false,
            autoplay: false,
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.solutions__next',
                prevEl: '.solutions__prev',
            },
            breakpoints: {
                991: {
                    slidesPerView: 3,
                }
            }
        })
    
        /** 
         * Client slider
        */
        const clientSwiper = new Swiper('.logos__slider', {
            loop: false,
            autoplay: false,
            slidesPerView: 3.5,
            autoplay: true,
            delay: 3000,
            speed: 400,
            spaceBetween: 10,
            navigation: {
                nextEl: '.logos__next',
                prevEl: '.logos__prev',
            },
            breakpoints: {
                991: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                }
            }
        })
    
        /** 
         * Benefits slider
        */
        const benefitsSwiper = new Swiper('.benefits__slider', {
        loop: false,
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: '.benefits__next',
            prevEl: '.benefits__prev',
        },
        breakpoints: {
            991: {
                slidesPerView: 3,
            }
        }
    })
    
        /**
         * Toggle accordian
         */
        $('.features__item-title').click((event) => {
            $(event.target).toggleClass('features__item-title--open')
    
            if (event.target.tagName === 'IMG') {
                $(event.target).parent().toggleClass('features__item-title--open')
            }
        })
    
        /**
         * Features Load more
         */
        $('#features__load').click((event) => {
            event.preventDefault()
            $('.features__wrapper').toggleClass('features__wrapper--open')
            $('#features__load').hide()
        })
    
        /** 
         * Toggle cat filters
        */
        $('.news__cat').click((event) => {
            const container = $('#news__article-wrapper ')
            const colorData = JSON.parse($(container).attr('data-colors'))
            const catId = $(event.target).attr('data-cat-id')
            let color = '#3bd481'
    
            if (colorData[catId]) {
                color = colorData[catId].color
            }
    
            $(event.target).toggleClass('active')
            
            const active = $(event.target).hasClass('active')
    
            if (active) {
                $(event.target).css({
                    'backgroundColor': color,
                    'borderColor': color,
                    'color': 'white'
                })
            } else {
                $(event.target).css({
                    'backgroundColor': 'white',
                    'borderColor': '#000',
                    'color': '#000'
                })
            }
        })
    
        /** 
         * Ajax callback for article display
        */
        function displayArticlesAJAX (response, status, container, domain, featuredPost) {
            if (response.length > 0 && status === "success") {
                const colorData = JSON.parse($(container).attr('data-colors'))
                response.forEach((item) => {
                    let featuredImageSrc = ''
                    let color = '#3bd481'
                    let name = ''
                    let additionalClasses = 'd-none'
    
                    const catId = item.categories[0]
                    const cat = colorData[catId]
                    
    
                    if (cat) {
                        color = colorData[catId].color
                        name = cat.name
                        additionalClasses = ''
                    }
    
                    if (item.status !== "publish") return
                    if (item._embedded['wp:featuredmedia']) {
                        featuredImageSrc = item._embedded['wp:featuredmedia'][0].source_url
                    }
                    const title = item.title.rendered
                    const permalink = item.link
                    const classes = (featuredPost === true) ? 'col-12' : 'col-xl-4 col-lg-4 col-sm-6'
                    featuredPost = false
                    $(container).append(
                    `<div class="${classes}">
                        <a href="${permalink}">
                            <div class="news__post" style="background-image:url(${featuredImageSrc})">
                                <div class="news__post-tag ${additionalClasses}" style="background-color:${color}">${name}</div>
                                <div class="news__post-title-wrapper d-flex justify-content-between align-items-end" style="background-color:${color}">
                                    <h4 class="news__post-title">${title}</h4>
                                    <img class="news__post-icon" src="${domain}/wp-content/themes/cloudhouse/assets/img/icons/right-white-arrow.png" alt="Read article">
                                </div>
    
                                <div class="home__news-overlay" style="background-color:${color};">
                                <h4 class="news__post-title">${title}</h4>
                                ${getExcerpt(item.excerpt.rendered, 140)}
    
                                <button class="button">READ MORE</button>
                                </div>
                            </div>
                        </a>
                    </div>`
                    )
                })
            }
        }
    
        function loadingAjax (isLoading) {
            (isLoading) ? $('.news__loader').show()  :$('.news__loader').hide()
            $('.news__tag, #load-posts').each((index, el) => {
                $(el).attr("disabled", isLoading)
            })
        }
    
        function getExcerpt(excerpt, length) {
            let newExcerpt = excerpt.replace(/(<([^>]+)>)/gi, "")
            newExcerpt = newExcerpt.substring(0, length - 3)
            newExcerpt = newExcerpt + '...'
            return newExcerpt
        }
    
        /**
         * Load more posts on News template
         */
        $('#load-posts, .news__tag, .news__cat').on('click', (event) => {
            if($(event.target).attr("disabled")) return
    
            let perPage = 6
            let tagQuery = ''
            let catQuery = ''
            let offsetQuery = ''
            let perPageQuery = `&per_page=${perPage}&_embed`
            let reset = false
            let featuredPost = false
    
            const totalPosts = $('#load-posts').attr('data-total')
            const domain = $('#load-posts').attr('data-domain')
            const container = $('#news__article-wrapper ')
    
            if (event.target.classList.contains('news__tag')) {
                event.target.classList.toggle('active')
    
                const activeTags = $('.news__tag.active')
    
                if (activeTags.length > 0) {
                    $('.news__tag.active').each((i, el) => {
                        const id = $(el).attr('data-tag-id')
                        tagQuery += `&tags[]=${id}`
                    })
                } else {
                    $('#load-posts').attr('data-offset', 0)
                    $('#load-posts').show()
                    reset = true
                }
            }
    
            if(event.target.classList.contains('news__cat')) {
                const activeCats = $('.news__cat.active')
                if (activeCats.length > 0) {
                    $('.news__cat.active').each((i, el) => {
                        const id = $(el).attr('data-cat-id')
                        catQuery += `&categories[]=${id}`
                    })
                } else {
                    $('#load-posts').attr('data-offset', 0)
                    $('#load-posts').show()
                    reset = true
                }
            }
    
            if (reset === true) {
                offsetQuery = `&offset=0`
                perPageQuery = `&per_page=7&_embed`
                $(container).html('')
                featuredPost = true
            }
    
            if (tagQuery !== '' || catQuery) {
                $(container).html('')
                perPage = 100
                perPageQuery = `&per_page=${perPage}&_embed`
                featuredPost = true
            } else {
                const offset = $('#load-posts').attr('data-offset')
                offsetQuery = `&offset=${offset}`
            }
    
            loadingAjax(true)
            $.get(`${domain}/wp-json/wp/v2/posts?${offsetQuery}${tagQuery}${catQuery}${perPageQuery}`)
                .then((response, status) => {
                    displayArticlesAJAX(response, status, container, domain, featuredPost)
                    const offset = parseInt($('#load-posts').attr('data-offset'))
                    if(offset > totalPosts || perPage == 100) $('#load-posts').hide()
                    $('#load-posts').attr('data-offset', offset + perPage)
                    featuredPost = false
                    loadingAjax(false)
                })
        })
    
        /** 
         * Content Dark Show more
        */
        $('#content-dark__load').click(() => {
            $('.content-dark').toggleClass('content-dark--hidden')
            $('#content-dark__load').hide()
        })
    })
    
    // Stop iframe video on close
    $('[data-stop-iframe]').click((event) => {
        const iframe = $(event.target).closest('.modal').find('iframe')
        const src = $(iframe).attr('src');
        $(iframe).attr('src', '');
        $(iframe).attr('src', src);
    })
})(jQuery)